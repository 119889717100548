import React, {useState, useEffect} from "react";
import FuneralDataService from "../services/FuneralService";
// import {Link} from "react-router-dom";
import ReactPlayer from 'react-player/vimeo'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon
} from "react-share";

const Funeral = props => {
    const [currentFuneral, setCurrentFuneral] = useState({});

    const getFuneral = slug => {
        FuneralDataService.get(slug)
            .then(response => {
                setCurrentFuneral(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getFuneral(props.match.params.slug);
    }, [props.match.params.slug]);


    return (
        <div>
            {currentFuneral ? (
                <div>
                    <div className="col-md-6">

                        <div>
                            <h2>{currentFuneral.funeral_name}</h2>
                            <p>The services will be streamed here from the time of the original service and afterwards.  If the stream is in any way interrupted, we will repost the recording here within 24 hours of the original service time.   </p>
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <strong>Name:</strong>*/}
                            {/*    </label>{" "}*/}
                            {/*    {currentFuneral.funeral_name}*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <label>*/}
                            {/*        <strong>Date:</strong>*/}
                            {/*    </label>{" "}*/}
                            {/*    {currentFuneral.funeral_startDateTime}*/}
                            {/*</div>*/}
                            <div>
                                <label>
                                    <strong>URL:</strong>
                                </label>{" "}
                                <a href={currentFuneral.funeral_vimeoURL}>{currentFuneral.funeral_vimeoURL}</a>
                            </div>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url={currentFuneral.funeral_vimeoURL}
                                    width='100%'
                                    height='100%'
                                />

                            </div>
                            <p>You may share this link with others on Facebook or by email by clicking one of these buttons:</p>
                            <FacebookShareButton
                                url={currentFuneral.funeral_vimeoURL}
                                quote={currentFuneral.funeral_name}
                                // className={classes.socialMediaButton}>
                            >
                                <FacebookIcon size={36}/>
                            </FacebookShareButton>
                            <EmailShareButton subject={currentFuneral.funeral_name}
                                              body={'Here is the link for the memorial service: ' + currentFuneral.funeral_vimeoURL}>
                                <EmailIcon size={36}/>
                            </EmailShareButton>


                            {/*<div>*/}
                            {/*    <ReactPlayer url={currentFuneral.funeral_vimeoURL}/>*/}
                            {/*</div>*/}

                            {/*<Link*/}
                            {/*    to={"/tutorials/" + currentFuneral.id}*/}
                            {/*    className="badge badge-warning"*/}
                            {/*>*/}
                            {/*    Edit*/}
                            {/*</Link>*/}
                        </div>
                        {currentFuneral.hasGraveside && currentFuneral.funeral_vimeoURL2 ? (
                            <div>
                                <h2>SECOND VIDEO (if required)</h2>
                                {/*<div>*/}
                                {/*    <label>*/}
                                {/*        <strong>Name:</strong>*/}
                                {/*    </label>{" "}*/}
                                {/*    {currentFuneral.funeral_name}*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <label>*/}
                                {/*        <strong>Date:</strong>*/}
                                {/*    </label>{" "}*/}
                                {/*    {currentFuneral.funeral_startDateTime}*/}
                                {/*</div>*/}
                                <div>
                                    <label>
                                        <strong>URL:</strong>
                                    </label>{" "}
                                    <a href={currentFuneral.funeral_vimeoURL2}>{currentFuneral.funeral_vimeoURL2}</a>
                                </div>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={currentFuneral.funeral_vimeoURL2}
                                        width='100%'
                                        height='100%'
                                    />

                                </div>
                                <FacebookShareButton
                                    url={currentFuneral.funeral_vimeoURL2}
                                    quote={currentFuneral.funeral_name}
                                    // className={classes.socialMediaButton}>
                                >
                                    <FacebookIcon size={36}/>
                                </FacebookShareButton>
                                <EmailShareButton subject={currentFuneral.funeral_name}
                                                  body={'Here is the link for the committal service: ' + currentFuneral.funeral_vimeoURL2}>
                                    <EmailIcon size={36}/>
                                </EmailShareButton>


                                {/*<div>*/}
                                {/*    <ReactPlayer url={currentFuneral.funeral_vimeoURL}/>*/}
                                {/*</div>*/}

                                {/*<Link*/}
                                {/*    to={"/tutorials/" + currentFuneral.id}*/}
                                {/*    className="badge badge-warning"*/}
                                {/*>*/}
                                {/*    Edit*/}
                                {/*</Link>*/}
                            </div>
                        ):<div></div>}
                    </div>
                </div>
            ) : (<div>No event found. Please try again.</div>)
            }
        </div>
    );
};

export default Funeral;
