import axios from "axios";

export default axios.create({
    // baseURL: "localhost:5000/api",
    baseURL : `${window.location.origin}/api`,
    // baseURL : (process.env.NODE_ENV === "development") ? `http://localhost:5000/api` : `${window.location.origin}/api`,
    headers: {
        "Content-type": "application/json"
    }
});
