import React from 'react';
import { Switch, Route} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import AddTutorial from "./components/AddTutorial";
import Tutorial from "./components/Tutorial";
import TutorialsList from "./components/TutorialsList";

import User from "./components/User";
import AddUser from "./components/AddUser";
import UsersList from "./components/UsersList";
import Home from "./components/Home";
import Funeral from "./components/Funeral";
import MgmtConsole from "./components/MgmtConsole";

function  App() {
  return (
  <div>
    {/*<nav className="navbar navbar-expand navbar-dark bg-dark">*/}
    {/*  <a href="/tutorials" className="navbar-brand">*/}
    {/*    Funeral Stream App*/}
    {/*  </a>*/}
    {/*  <div className="navbar-nav mr-auto">*/}
    {/*    <li className="nav-item">*/}
    {/*      <Link to={"/tutorials"} className="nav-link">*/}
    {/*        Tutorials*/}
    {/*      </Link>*/}
    {/*    </li>*/}
    {/*    <li className="nav-item">*/}
    {/*      <Link to={"/add_tutorial"} className="nav-link">*/}
    {/*        Add Tutorial*/}
    {/*      </Link>*/}
    {/*    </li>*/}
    {/*    <li className="nav-item">*/}
    {/*      <Link to={"/users"} className="nav-link">*/}
    {/*        Users*/}
    {/*      </Link>*/}
    {/*    </li>*/}
    {/*    <li className="nav-item">*/}
    {/*      <Link to={"/add_user"} className="nav-link">*/}
    {/*        Add User*/}
    {/*      </Link>*/}
    {/*    </li>*/}
    {/*  </div>*/}
    {/*</nav>*/}

    <div className="container mt-3">
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/mgmt" component={MgmtConsole}/>
        <Route exact path="/users" component={UsersList} />
        <Route exact path="/tutorials" component={TutorialsList} />
        <Route path="/:slug" component={Funeral} />
        <Route exact path="/add_tutorial" component={AddTutorial} />
        <Route exact path="/add_user" component={AddUser} />
        <Route path="/users/:id" component={User} />
        <Route path="/tutorials/:id" component={Tutorial} />
      </Switch>
    </div>
  </div>
  );
}

export default App;
