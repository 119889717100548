import React from "react";

const Home = () => {

    return (
        <h1>Please visit <a href="http://www.boylanfuneralservices.com">www.boylanfuneralservices.com</a></h1>
    );
};

export default Home;
