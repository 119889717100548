import React, { useState, useEffect } from "react";
import UserDataService from "../services/UserService";

const User = props => {
    const initialUserState = {
        id: null,
        title: "",
        description: "",
        published: false
    };
    const [currentUser, setCurrentUser] = useState(initialUserState);
    const [message, setMessage] = useState("");

    const getUser = id => {
        UserDataService.get(id)
            .then(response => {
                setCurrentUser(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getUser(props.match.params.id);
    }, [props.match.params.id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentUser({ ...currentUser, [name]: value });
    };

    // const updatePublished = status => {
    //     var data = {
    //         id: currentUser.id,
    //         title: currentUser.title,
    //         description: currentUser.description,
    //         published: status
    //     };
    //
    //     UserDataService.update(currentUser.id, data)
    //         .then(response => {
    //             setCurrentUser({ ...currentUser, published: status });
    //             console.log(response.data);
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // };

    const updateUser = () => {
        UserDataService.update(currentUser.id, currentUser)
            .then(response => {
                console.log(response.data);
                setMessage("The user was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteUser = () => {
        UserDataService.remove(currentUser.id)
            .then(response => {
                console.log(response.data);
                props.history.push("/users");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div>
            {currentUser ? (
                <div className="edit-form">
                    <h4>User</h4>
                    <form>
                        <div className="form-group">
                            <label htmlFor="userName">User Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="userName"
                                name="userName"
                                value={currentUser.user_userName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={currentUser.user_email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="text"
                                className="form-control"
                                id="password"
                                name="password"
                                value={currentUser.user_password}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Real Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={currentUser.user_name}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={currentUser.user_phone}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/*<div className="form-group">*/}
                        {/*    <label>*/}
                        {/*        <strong>Status:</strong>*/}
                        {/*    </label>*/}
                        {/*    {currentUser.published ? "Published" : "Pending"}*/}
                        {/*</div>*/}
                    </form>

                    {/*{currentUser.published ? (*/}
                    {/*    <button*/}
                    {/*        className="badge badge-primary mr-2"*/}
                    {/*        onClick={() => updatePublished(false)}*/}
                    {/*    >*/}
                    {/*        UnPublish*/}
                    {/*    </button>*/}
                    {/*) : (*/}
                    {/*    <button*/}
                    {/*        className="badge badge-primary mr-2"*/}
                    {/*        onClick={() => updatePublished(true)}*/}
                    {/*    >*/}
                    {/*        Publish*/}
                    {/*    </button>*/}
                    {/*)}*/}

                    <button className="badge badge-danger mr-2" onClick={deleteUser}>
                        Delete
                    </button>

                    <button
                        type="submit"
                        className="badge badge-success"
                        onClick={updateUser}
                    >
                        Update
                    </button>
                    <p>{message}</p>
                </div>
            ) : (
                <div>
                    <br />
                    <p>Please click on a User...</p>
                </div>
            )}
        </div>
    );
};

export default User;
