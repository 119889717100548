import http from "../http-common";

const getAll = () => {
    return http.get("/funerals");
};

const get = slug => {
    return http.get(`/funerals/${slug}`);
};

const create = data => {
    return http.post("/funerals", data);
};
//
const update = (id, data) => {
    return http.put(`/funerals/${id}`, data);
};
//
const remove = id => {
    return http.delete(`/funerals/${id}`);
};
//
// const removeAll = () => {
//     return http.delete(`/funerals`);
// };

const findByTitle = name => {
    return http.get(`/funerals?name=${name}`);
};

export default {
    getAll,
    get,
    create,
    update,
    remove,
    // removeAll,
    findByTitle
};
