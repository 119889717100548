import React, { useState } from "react";
import UserDataService from "../services/UserService";

const AddUser = () => {
    const initialUserState = {
        id: null,
        user_userName: "",
        user_email: "",
        user_password: "",
        user_name: "",
        user_phone: "",
        user_phoneVerified: false,
        user_emailVerified: false
    };
    const [user, setUser] = useState(initialUserState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    };

    const saveUser = () => {
        var data = {
            userName: user.user_userName,
            email: user.user_email,
            password: user.user_password,
            name: user.user_name,
            phone: user.user_phone
        };

        UserDataService.create(data)
            .then(response => {
                setUser({
                    id: response.data.id,
                    userName: response.data.user_userName,
                    email: response.data.user_email,
                    password: response.data.user_password,
                    name: response.data.user_name,
                    phone: response.data.user_phone
                });
                setSubmitted(true);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const newUser = () => {
        setUser(initialUserState);
        setSubmitted(false);
    };

    return (
        <div className="submit-form">
            {submitted ? (
                <div>
                    <h4>You submitted successfully!</h4>
                    <button className="btn btn-success" onClick={newUser}>
                        Add
                    </button>
                </div>
            ) : (
                <div>
                    <div className="form-group">
                        <label htmlFor="userName">User Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="userName"
                            required
                            value={user.user_userName}
                            onChange={handleInputChange}
                            name="userName"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            required
                            value={user.user_email}
                            onChange={handleInputChange}
                            name="email"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="text"
                            className="form-control"
                            id="password"
                            required
                            value={user.user_password}
                            onChange={handleInputChange}
                            name="password"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Real Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            required
                            value={user.user_name}
                            onChange={handleInputChange}
                            name="name"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            required
                            value={user.user_phone}
                            onChange={handleInputChange}
                            name="phone"
                        />
                    </div>

                    <button onClick={saveUser} className="btn btn-success">
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddUser;
