import React, {useState, useEffect} from "react";
import {forwardRef} from 'react';
import {useTable} from 'react-table'
// import MgmtConsoleDataService from "../services/MgmtConsoleService";
import UserService from "../services/UserService";
import QueueService from "../services/QueueService";
import FuneralService from "../services/FuneralService";
import Grid from '@material-ui/core/Grid'
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import ReactPlayer from "react-player/vimeo";
// import {Link} from "react-router-dom";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';


const MgmtConsole = () => {
    const [users, setUsers] = useState([]);
    const [funerals, setFunerals] = useState([]);
    const [queues, setQueues] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const queueColumns = [
        {
            title: "id", field: "id", hidden: true
        },
        {
            title: 'Queue Name',
            field: 'queue_name', // accessor is the "key" in the data
        },
        {
            title: 'Sorted Order',
            field: 'queue_order',
        },
        {
            title: 'Users',
            render: (data) => {

                return Array.prototype.map.call(data.users, function (item) {
                    return item.user_userName;
                }).join(",");

            },
        }
    ]
    const userColumns = [
        {
            title: 'Username',
            field: 'user_userName',
        },
        {
            title: 'Email',
            field: 'user_email',
        },
        {
            title: 'Password',
            field: 'user_password',
            render: () => null,
            editComponent: props => (
                <input
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Full Name',
            field: 'user_name',
        },
        {
            title: 'Phone',
            field: 'user_phone',
        },
        {
            title: 'Valid Phone?',
            field: 'user_phoneVerified',
            type: 'boolean'
        },
        {
            title: 'Valid Email?',
            field: 'user_emailVerified',
            type: 'boolean'
        }
    ]

    const funeralColumns = [
        {
            title: 'Name',
            field: 'funeral_name',
        },
        {
            title: 'Slug',
            field: 'funeral_slug',
        },
        {
            title: 'Graveside?',
            field: 'hasGraveside',
            type: 'boolean'
        },
        {
            title: 'Vimeo URL',
            field: 'funeral_vimeoURL',
        },
        {
            title: 'Graveside Vimeo URL',
            field: 'funeral_vimeoURL2',
        },
        {
            title: 'Start Date',
            field: 'funeral_startDateTime',
            type: 'datetime',
        }
    ]

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
    };

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])


    const getQueues = () => {
        QueueService.getAll()
            .then(response => {
                setQueues(response.data);
            })
            .catch(e => {
                setIserror(true)
                console.log(e);
            });
    }
    const getUsers = () => {
        UserService.getAll()
            .then(response => {
                setUsers(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }
    const getFunerals = () => {
        FuneralService.getAll()
            .then(response => {
                setFunerals(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }
    const handleQueueRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if (newData.queue_name === "") {
            errorList.push("Please enter queue name")
        }
        if (newData.queue_order === "") {
            errorList.push("Please enter queue order")
        }

        if (errorList.length < 1) {
            QueueService.update(newData.id, newData)
                .then(res => {
                    const dataUpdate = [...queues];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setQueues([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }

    const handleQueueRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if (newData.queue_name === undefined) {
            errorList.push("Please enter queue name")
        }
        if (newData.queue_order === undefined) {
            errorList.push("Please enter queue order")
        }


        if (errorList.length < 1) { //no error
            QueueService.create(newData)
                .then(res => {
                    let dataToAdd = [...queues];
                    dataToAdd.push(newData);
                    setQueues(dataToAdd);
                    resolve()
                    setErrorMessages([])
                    setIserror(false)
                })
                .catch(error => {
                    setErrorMessages(["Cannot add data. Server error!"])
                    setIserror(true)
                    resolve()
                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()
        }


    }

    const handleQueueRowDelete = (oldData, resolve) => {
        QueueService.remove(oldData.id)
            .then(res => {
                const dataDelete = [...queues];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setQueues([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    const handleFuneralRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if (newData.funeral_name === "") {
            errorList.push("Please enter funeral name")
        }
        if (newData.funeral_slug === "") {
            errorList.push("Please enter funeral slug")
        }

        if (errorList.length < 1) {
            FuneralService.update(newData.id, newData)
                .then(res => {
                    const dataUpdate = [...funerals];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setFunerals([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }

    const handleFuneralRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if (newData.funeral_name === undefined) {
            errorList.push("Please enter funeral name")
        }
        if (newData.funeral_slug === undefined) {
            errorList.push("Please enter funeral slug")
        }


        if (errorList.length < 1) { //no error
            FuneralService.create(newData)
                .then(res => {
                    let dataToAdd = [...funerals];
                    dataToAdd.push(newData);
                    setFunerals(dataToAdd);
                    resolve()
                    setErrorMessages([])
                    setIserror(false)
                })
                .catch(error => {
                    setErrorMessages(["Cannot add data. Server error!"])
                    setIserror(true)
                    resolve()
                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()
        }


    }

    const handleFuneralRowDelete = (oldData, resolve) => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                const dataDelete = [...funerals];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setFunerals([...dataDelete]);
                FuneralService.remove(oldData.id)
                    .then(res => {
                        const dataDelete = [...funerals];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setFunerals([...dataDelete]);
                        resolve()
                    })
                    .catch(error => {
                        setErrorMessages(["Delete failed! Server error"])
                        setIserror(true)
                        resolve()
                    });
                resolve();
            }, 1000);
        })

    }

    const handleUserRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if (newData.user_userName === "") {
            errorList.push("Please enter user name")
        }

        if (errorList.length < 1) {
            UserService.update(newData.id, newData)
                .then(res => {
                    const dataUpdate = [...users];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setUsers([...dataUpdate]);
                    resolve()
                    setIserror(false)
                    setErrorMessages([])
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }

    const handleUserRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if (newData.user_userName === undefined) {
            errorList.push("Please enter user name")
        }


        if (errorList.length < 1) { //no error
            UserService.create(newData)
                .then(res => {
                    let dataToAdd = [...users];
                    dataToAdd.push(newData);
                    setUsers(dataToAdd);
                    resolve()
                    setErrorMessages([])
                    setIserror(false)
                })
                .catch(error => {
                    setErrorMessages(["Cannot add data. Server error!"])
                    setIserror(true)
                    resolve()
                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()
        }


    }

    const handleUserRowDelete = (oldData, resolve) => {
        UserService.remove(oldData.id)
            .then(res => {
                const dataDelete = [...users];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setUsers([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }

    useEffect(() => {
        getUsers();
        getFunerals();
        // getQueues();
    }, []);


    return (
        <div>
            {errorMessages}
            <h1>Mgmt console</h1>
            {/*<div className="list row">*/}
            {/*    <div className="col-md-12">*/}
            <MaterialTable
                title="Funerals"
                columns={funeralColumns}
                data={funerals}
                icons={tableIcons}
	    options={{
    rowStyle: {
      fontSize: 12,
    }
  }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...funerals];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setFunerals([...dataUpdate]);
                                FuneralService.update(newData.id, newData)
                                    .then(res => {
                                        const dataUpdate = [...funerals];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setFunerals([...dataUpdate]);
                                        resolve()
                                        setIserror(false)
                                        setErrorMessages([])
                                    })
                                    .catch(error => {
                                        setErrorMessages(["Update failed! Server error"])
                                        setIserror(true)
                                        resolve()

                                    })
                                resolve();
                            }, 1000)
                        }),
                    // new Promise((resolve) => {
                    //     handleFuneralRowUpdate(newData, oldData, resolve);
                    // }),
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                setFunerals([...funerals, newData]);
                                FuneralService.create(newData)
                                    .then(res => {
                                        let dataToAdd = [...funerals];
                                        dataToAdd.push(newData);
                                        setFunerals(dataToAdd);
                                        resolve()
                                        setErrorMessages([])
                                        setIserror(false)
                                    })
                                    .catch(error => {
                                        setErrorMessages(["Cannot add data. Server error!"])
                                        setIserror(true)
                                        resolve()
                                    })
                                resolve();
                            }, 1000)
                            // new Promise((resolve) => {
                            //     handleFuneralRowAdd(newData, resolve)
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...funerals];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setFunerals([...dataDelete]);
                                FuneralService.remove(oldData.id)
                                    .then(res => {
                                        const dataDelete = [...funerals];
                                        const index = oldData.tableData.id;
                                        dataDelete.splice(index, 1);
                                        setFunerals([...dataDelete]);
                                        resolve()
                                    })
                                    .catch(error => {
                                        setErrorMessages(["Delete failed! Server error"])
                                        setIserror(true)
                                        resolve()
                                    });
                                resolve();
                            }, 1000);
                        })

                }}
                detailPanel={rowData => {
                    console.log(rowData['funeral_vimeoURL']);
                    return (
                        <div className="row">
                        <div className="col-md-6">
                        <ReactPlayer url={rowData['funeral_vimeoURL']} controls/>
                        </div>
                        <div className="col-md-6">
                        <ReactPlayer url={rowData['funeral_vimeoURL2']} controls/>
                        </div>
                        </div>

                    )
                }}
                actions={[
                    {
                        icon: Search,
                        tooltip: 'Preview',
                        onClick: (event, rowData) => window.open(rowData.funeral_slug)
                    }
                ]}
            />
            {/*<MaterialTable*/}
            {/*    title="Queues"*/}
            {/*    columns={queueColumns}*/}
            {/*    data={queues}*/}
            {/*    icons={tableIcons}*/}
            {/*    editable={{*/}
            {/*        onRowUpdate: (newData, oldData) =>*/}
            {/*            new Promise((resolve) => {*/}
            {/*                handleQueueRowUpdate(newData, oldData, resolve);*/}
            {/*            }),*/}
            {/*        onRowAdd: (newData) =>*/}
            {/*            new Promise((resolve) => {*/}
            {/*                handleQueueRowAdd(newData, resolve)*/}
            {/*            }),*/}
            {/*        onRowDelete: (oldData) =>*/}
            {/*            new Promise((resolve) => {*/}
            {/*                handleQueueRowDelete(oldData, resolve)*/}
            {/*            }),*/}
            {/*    }}*/}
            {/*    detailPanel={rowData => {*/}
            {/*        console.log(rowData);*/}
            {/*        return (<div>Hi</div>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*/>*/}
            <MaterialTable
                title="Users"
                columns={userColumns}
                data={users}
                icons={tableIcons}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            handleUserRowUpdate(newData, oldData, resolve);
                        }),
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            handleUserRowAdd(newData, resolve)
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleUserRowDelete(oldData, resolve)
                        }),
                }}
            />

        </div>
        //     </div>
        // </div>
    );
};

export default MgmtConsole;
